/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bracket': {
    width: 16,
    height: 13,
    viewBox: '0 0 16 13',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.222 1.556H1.778a.889.889 0 00-.89.889v1.778c0 .49.399.888.89.888h12.444c.491 0 .89-.398.89-.888V2.445a.889.889 0 00-.89-.89zM1.778.667C.796.667 0 1.463 0 2.445v1.778C0 5.204.796 6 1.778 6h12.444C15.204 6 16 5.204 16 4.223V2.445c0-.982-.796-1.778-1.778-1.778H1.778zM14.222 7.778H1.778a.889.889 0 00-.89.889v1.778c0 .49.399.889.89.889h12.444c.491 0 .89-.398.89-.89V8.668a.889.889 0 00-.89-.889zM1.778 6.89C.796 6.89 0 7.685 0 8.667v1.778c0 .982.796 1.777 1.778 1.777h12.444c.982 0 1.778-.795 1.778-1.777V8.667c0-.982-.796-1.778-1.778-1.778H1.778z" _fill="#fff"/>'
  }
})
